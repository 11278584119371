import { blockchainHandler } from '@/blockchainHandlers'
import { when, computed, action, reaction, IReactionDisposer, observable } from 'mobx'
import { walletStore } from '@/stores/wallet-store'
import { asyncAction } from 'mobx-utils'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { loadingController } from '@/components/global-loading/global-loading-controller'
import { bnHelper } from '@/helpers/bignumber-helper'
import { FixedNumber } from '@ethersproject/bignumber'
import moment from 'moment'
import { EmpirelandBoxNftHandler } from '@/blockchainHandlers/empirelandBoxHandler'
import { EmpirelandSaleHandler } from '@/blockchainHandlers/empirelandSaleHandler'
import { takeUntil } from 'rxjs/operators'
import { appProvider } from './../../../app-providers'
import { Subject, timer } from 'rxjs'

export default class MintViewModel {
  _disposers: IReactionDisposer[] = []
  private _unsubcrible = new Subject()
  @observable minting = false
  @observable heroSummoning = false
  @observable currentStep = 'summon'
  @observable nftMintAmount = 1
  @observable openMintDialog = false
  @observable openSummonDialog = false
  @observable totalNftMinted = 0
  @observable mintType = ''
  @observable summonable = true
  @observable txFee
  @observable ftmInfo
  @observable nftTotalSupply
  @observable nftLeft
  @observable salePercentage
  @observable nftMinted
  @observable holdingBoxes = []
  @observable approved = false
  @observable txHash = ''

  // empirelandSaleHandler?: any
  empirelandBoxHandler?: any

  constructor() {
    // this.empirelandSaleHandler = new EmpirelandSaleHandler()
    this.empirelandBoxHandler = new EmpirelandBoxNftHandler()
    this.loadData()
    this._disposers = [
      reaction(
        () => walletStore.account,
        () => {
          if (walletStore.isChainIdValid) {
            this.loadData()
            this.empirelandBoxHandler?.injectMetamask(walletStore.web3!)
          }
        }
      ),
    ]

    this._disposers.push(
      when(
        () => walletStore.connected,
        async () => {
          if (walletStore.chainId === Number(process.env.VUE_APP_CHAIN_ID)) {
            this.empirelandBoxHandler?.injectMetamask(walletStore.web3!)
            // this.empirelandSaleHandler?.injectMetamask(walletStore.web3!)
            timer(0, 5000)
              .pipe(takeUntil(this._unsubcrible))
              .subscribe(async () => {
                this.loadData()
              })
          }
        }
      )
    )
  }

  destroy() {
    this._unsubcrible.next()
    this._unsubcrible.complete()
    this._disposers.forEach((d) => d())
  }

  @asyncAction *loadData() {
    try {
      if (walletStore.account) {
        const boxHolding = yield this.empirelandBoxHandler?.getHoldingTokens(walletStore.account)
        if (!(this.holdingBoxes.length > 0 && this.holdingBoxes.length < boxHolding.length))
          this.holdingBoxes = boxHolding
      }
      yield this.setNftMinted()

      this.nftTotalSupply = 10000
      this.nftLeft = this.nftTotalSupply - this.nftMinted
      this.salePercentage = Math.floor((this.nftMinted / this.nftTotalSupply) * 100)
    } catch (error: any) {
      snackController.commonError(error)
    }
  }

  @asyncAction *approveHeroBox() {
    try {
      yield this.empirelandBoxHandler.approveContract()
    } catch (error: any) {
      snackController.commonError(error)
    }
  }

  async summonHero(amount: number) {
    try {
      this.heroSummoning = true
      await this.approveHeroBox()
      const ids = this.holdingBoxes?.slice(0, amount)
      const res = await appProvider.api.heroNft.redeem({
        receiver: walletStore.account,
        burnIDs: ids,
      })

      this.txHash = res
      if (res) {
        snackController.success(`You have summoned ${amount} ${amount > 1 ? 'heros' : 'hero'}`)
        this.holdingBoxes = this.holdingBoxes.filter((item) => !ids.includes(item))
      }
    } catch (error: any) {
      error.message && snackController.commonError(error)
    } finally {
      this.heroSummoning = false
      this.setOpenSummonDialog(false)
      this.resetNFTMintAmount()
    }
  }

  @action setCurrentStep(step: string) {
    this.currentStep = step
  }

  @action setOpenMintDialog(status: boolean) {
    this.openMintDialog = status
  }

  @action setOpenSummonDialog(status: boolean) {
    this.openSummonDialog = status
  }

  @action resetNFTMintAmount() {
    this.nftMintAmount = 1
  }

  async setNftMinted() {
    const nftsSummoned = await appProvider.api.heroNft.count({ distributed: true })
    this.nftMinted = nftsSummoned
  }

  @computed get boxNumber(): number {
    return this.holdingBoxes?.length || 0
  }

  @computed get boxOpenNumber(): string {
    const text =
      this.nftMinted && this.nftTotalSupply
        ? `${new Intl.NumberFormat('en-US').format(this.nftMinted) || ''} / ${
            new Intl.NumberFormat('en-US').format(this.nftTotalSupply) || ''
          }`
        : ''
    return text
  }

  @computed get percentageText() {
    return this.salePercentage ? `${this.salePercentage}%` : ''
  }

  @computed get nftSummonedNumber() {
    return this.nftMinted ? new Intl.NumberFormat('en-US').format(this.nftMinted) : ''
  }
}
