



















































































































































































































































import { Observer } from 'mobx-vue'
import { Component, Vue, Provide } from 'vue-property-decorator'
import MintViewModel from '../viewmodels/mint-viewmodel'
import { walletStore } from '@/stores/wallet-store'

@Observer
@Component({
  components: {
    MintDialog: () => import('../components/mint-dialog.vue'),
    SummonDialog: () => import('../components/summon-dialog.vue'),
  },
})
export default class Mint extends Vue {
  @Provide() vm = new MintViewModel()
  wallet = walletStore
  chainId = Number(process.env.VUE_APP_CHAIN_ID)

  copyAddress() {
    navigator.clipboard.writeText(this.vm.txHash!)
  }
}
