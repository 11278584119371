import { walletStore } from '@/stores/wallet-store'
import Web3 from 'web3'
import { blockchainHandler } from '@/blockchainHandlers'

const web3 = blockchainHandler.getWeb3(process.env.VUE_APP_CHAIN_ID)!

async function sendRequest(fx, from) {
  return await new Promise((resolve, reject) => {
    fx.send({ from })
      .on('receipt', (result) => resolve(result))
      .on('error', (error) => reject(error))
  })
}

export class EmpirelandBoxNftHandler {
  contract: any

  constructor() {
    this.contract = new web3.eth.Contract(require('./abis/empirelandBox.json'), process.env.VUE_APP_BOX_NFT_ADDRESS)
  }

  injectMetamask(web3: Web3) {
    if (web3) {
      this.contract = new web3.eth.Contract(require('./abis/empirelandBox.json'), process.env.VUE_APP_BOX_NFT_ADDRESS)
    }
  }

  async getHoldingTokens(address: string) {
    const methods = this.contract.methods
    const balance = await methods.balanceOf(walletStore.account).call()
    const promises: Promise<number>[] = []
    for (let i = 0; i < balance; i++) {
      const promise: Promise<number> = methods.tokenOfOwnerByIndex(walletStore.account, i).call()
      promises.push(promise)
    }
    return Promise.all(promises)
  }

  async approveContract() {
    const REDEEM_CONTRACT = process.env.VUE_APP_REDEEM_CONTRACT
    const methods = this.contract.methods
    const account = walletStore.account
    const isApproved = await methods.isApprovedForAll(account, REDEEM_CONTRACT).call()
    if (!isApproved) await sendRequest(methods.setApprovalForAll(REDEEM_CONTRACT, true), account)
  }
}
